<template>
  <!-- <div class="modal fade" id="calculator" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Define Quantity</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="calculator-set">
            <div class="calculatortotal">
              <h4>0</h4>
            </div>
            <ul>
              <li>
                <a href="javascript:void(0);">1</a>
              </li>
              <li>
                <a href="javascript:void(0);">2</a>
              </li>
              <li>
                <a href="javascript:void(0);">3</a>
              </li>
              <li>
                <a href="javascript:void(0);">4</a>
              </li>
              <li>
                <a href="javascript:void(0);">5</a>
              </li>
              <li>
                <a href="javascript:void(0);">6</a>
              </li>
              <li>
                <a href="javascript:void(0);">7</a>
              </li>
              <li>
                <a href="javascript:void(0);">8</a>
              </li>
              <li>
                <a href="javascript:void(0);">9</a>
              </li>
              <li>
                <a href="javascript:void(0);" class="btn btn-closes"><img src="../../../assets/img/icons/close-circle.svg"
                    alt="img" /></a>
              </li>
              <li>
                <a href="javascript:void(0);">0</a>
              </li>
              <li>
                <a href="javascript:void(0);" class="btn btn-reverse"><img src="../../../assets/img/icons/reverse.svg"
                    alt="img" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="holdsales" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Hold order</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="hold-order">
            <h2>4500.00</h2>
          </div>
          <div class="form-group">
            <label>Order Reference</label>
            <input type="text" />
          </div>
          <div class="para-set">
            <p>
              The current order will be set on hold. You can retreive this order from the
              pending order button. Providing a reference to it might help you to identify
              the order more quickly.
            </p>
          </div>
          <div class="col-lg-12">
            <a class="btn btn-submit me-2">Submit</a>
            <a class="btn btn-cancel" data-bs-dismiss="modal">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="edit" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Order</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Product Price</label>
                <input type="text" value="20" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Product Price</label>
                <select class="select">
                  <option>Exclusive</option>
                  <option>Inclusive</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label> Tax</label>
                <div class="input-group">
                  <input type="text" />
                  <a class="scanner-set input-group-text"> % </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Discount Type</label>
                <select class="select">
                  <option>Fixed</option>
                  <option>Percentage</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Discount</label>
                <input type="text" value="20" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Sales Unit</label>
                <select class="select">
                  <option>Kilogram</option>
                  <option>Grams</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <a class="btn btn-submit me-2">Submit</a>
            <a class="btn btn-cancel" data-bs-dismiss="modal">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <form @submit.prevent="pilihmarketing">
    <div class="modal fade" id="pilihmarketing" tabindex="-1" aria-labelledby="pilihmarketing" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h5 class="modal-title">Pilih Marketing
              </h5>
              <span for="">pilih marketing untuk item ini</span>
            </div>

            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
              ref="buttonclosemodalpilihmarketing">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Customer</label>
                  <a href="javascript:void(0);">{{ customer }}</a>

                </div>
              </div> -->
              <div class="col-lg-12">
                <div class="select-split">
                  <div class="select-group w-100">
                    <model-select :options="marketingdata" placeholder="Pilih Marketing" v-model="marketing_id">
                    </model-select>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-lg-12">
              <button class="btn btn-submit me-2" type="submit">Submit</button>
              <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form @submit.prevent="konfirmasi">
    <div class="modal fade" id="confirmation" tabindex="-1" aria-labelledby="confirmation" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h5 class="modal-title">Konfirmasi
              </h5>
              <span for="">cek data apakah sudah benar</span>
            </div>

            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
              ref="buttonclosemodalconfirmation">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Customer</label>
                  <a href="javascript:void(0);">{{ customer }}</a>
                  <!-- <input type="text" v-model="customer" disabled /> -->
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Total</label>
                  <a href="javascript:void(0);">{{ rupiah(total) }}</a>
                  <!-- <input type="text" v-model="total" disabled /> -->
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Metode Pembayaran</label>
                  <a href="javascript:void(0);">{{ paymentmethod }}</a>
                  <!-- <input type="text" v-model="paymentmethod" /> -->
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Jumlah Pembayaran <vue-feather type="copy" data-bs-toggle="tooltip" title="fe fe-copy"
                      class="me-2  text-primary" @click="fullPay()" style=""></vue-feather></label>
                  <!-- <a href="javascript:void(0);">{{ paymentmethod }}</a> -->
                  <input type="text" v-model="payment_amount" @input="formatCurrency(payment_amount)" />
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Kembalian</label>
                  <a href="javascript:void(0);">{{ rupiah(return_amount) }}</a>
                  <!-- <input type="text" v-model="paymentmethod" /> -->
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Note</label>
                  <textarea class="form-control" v-model="notes">
                    </textarea>
                </div>
              </div>

            </div>
            <div class="col-lg-12">
              <button class="btn btn-submit me-2" type="submit">Submit</button>
              <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form @submit.prevent="proses">
    <div class="modal fade" id="create" tabindex="-1" aria-labelledby="create" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h5 class="modal-title">Add Customer
              </h5>
              <span for="">create new customer</span>
            </div>

            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" ref="buttonclosemodalcreate">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Fullname<span class="text-danger">*</span></label>
                  <input type="text" v-model="currentData.fullname" required />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" v-model="currentData.email" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Phone<span class="text-danger">*</span></label>
                  <input type="text" v-model="currentData.telepone" required />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Whatsapp<span class="text-danger">*</span></label>
                  <input type="text" v-model="currentData.whatsapp" required />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date of Birth </label>
                  <div class="input-groupicon" style="z-index: 100;">
                    <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.dob" />
                    <a class="addonset">
                      <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Job Tittle</label>
                  <input type="text" v-model="currentData.job_title" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Company</label>
                  <input type="text" v-model="currentData.company" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Industry</label>
                  <input type="text" v-model="currentData.industry" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Interest</label>
                  <input type="text" v-model="currentData.interest" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-12">
                <div class="form-group">
                  <label>Discount Rate (%)</label>
                  <input type="text" v-model="currentData.discount_rate" />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Pricing Level<span class="text-danger">*</span></label>
                  <vue-select :options="pricingList" :settings="{ settingOption: value, settingOption: value }"
                    v-model="currentData.rate" placeholder="None" required />
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Billing Address</label>
                  <input type="text" v-model="currentData.billing_address1" />
                </div>
                <div class="form-group">
                  <input type="text" v-model="currentData.billing_address2" />
                </div>
                <div class="form-group">
                  <input type="text" v-model="currentData.billing_address3" />
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Shipping Address - [<a href="javascript:void(0);" @click="copyFromBillingAddres()">Copy from
                      billing
                      address</a>]</label>

                  <input type="text" v-model="currentData.shipping_address1" />
                </div>
                <div class="form-group">
                  <input type="text" v-model="currentData.shipping_address2" />
                </div>
                <div class="form-group">
                  <input type="text" v-model="currentData.shipping_address3" />
                </div>
              </div>

            </div>
            <div class="col-lg-12">
              <button class="btn btn-submit me-2" type="submit">Submit</button>
              <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form @submit.prevent="dailyreport">
    <div class="modal fade" id="dailyreport" tabindex="-1" aria-labelledby="dailyreport" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header" v-show="showbutton == true">
            <div>
              <h5 class="modal-title">Daily Report
              </h5>
              <span for="">check your daily report</span>
            </div>

            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
              ref="buttonclosemodaldailyreport">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="d-flex justify-content-center text-center">

                <div class="form-group" v-show="showbutton == true">
                  <label>Report Date</label>
                  <div class="input-groupicon" style="z-index: 100;">
                    <datepicker v-model="report_date" class="picker" :editable="true" :clearable="false" required />
                    <a class="addonset">
                      <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                    </a>
                  </div>
                  <button class="btn btn-submit w-100 mt-2" type="button" @click="dailyreport()">Generate</button>
                  <button class="btn btn-submit bg-danger text-white w-100 mt-2" type="button" @click="printApi()"
                    v-if="currentDataReport.item != undefined">Print</button>
                  <!-- <input type="text" v-model="customer" disabled /> -->
                </div>



              </div>
              <div class="" v-if="currentDataReport.header != undefined">
                <h5 class="modal-title mb-4">Report Summary
                </h5>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <label for="">Total Sales</label>
                  </div>
                  <div>
                    <label for="">{{ rupiah(currentDataReport.header.total_sales) }}</label>
                  </div>


                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <label for="">Report Date</label>
                  </div>
                  <div>
                    <label for="">{{ currentDataReport.header.report_date }}</label>
                  </div>


                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <label for="">Printed by</label>
                  </div>
                  <div>
                    <label for="">{{ currentDataReport.header.printed_by }}</label>
                  </div>


                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <label for="">Printed Date</label>
                  </div>
                  <div>
                    <label for="">{{ currentDataReport.header.printed_date }}</label>
                  </div>


                </div>

                <h5 class="modal-title mb-4 mt-4">Report Detail Item
                </h5>
                <table style="width:100%!important">
                  <tr>
                    <th style="width:75%!important" class="fw-bold">
                      Name
                    </th>
                    <th style="width:10%!important" class="fw-bold text-end">
                      Price
                    </th>
                    <th style="width:5%!important" class="fw-bold text-end">
                      QTY
                    </th>
                    <th style="width:10%!important" class="fw-bold text-end">
                      Total
                    </th>
                  </tr>

                  <tbody v-for="(item, index) in currentDataReport.item" :key="item.id">
                    <tr>
                      <td>{{ item.item_name }}</td>
                      <td class="text-end">{{ rupiah(item.item_price) }}</td>
                      <td class="text-end">{{ item.item_qty }}</td>
                      <td class="text-end">{{ rupiah(item.item_total) }}</td>
                    </tr>

                  </tbody>
                </table>

                <h5 class="modal-title mb-4 mt-4">Report Detail Payment
                </h5>
                <table style="width:100%!important">
                  <tr>
                    <th style="width:90%!important" class="fw-bold">
                      Method
                    </th>
                    <th style="width:10%!important" class="text-end fw-bold">
                      Amount
                    </th>

                  </tr>

                  <tbody v-for="(item, index) in currentDataReport.payment" :key="item.id">
                    <tr>
                      <td>{{ item.payment_method }}</td>
                      <td class="text-end">{{ rupiah(item.payment_amount) }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>


              <!-- <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label></label>
                  <a href="javascript:void(0);">{{ rupiah(total) }}</a>

                </div>
              </div> -->
              <!-- <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Total</label>
                  <a href="javascript:void(0);">{{ rupiah(total) }}</a>
                  
                </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Metode Pembayaran</label>
                  <a href="javascript:void(0);">{{ paymentmethod }}</a>
                  
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Note</label>
                  <textarea class="form-control" v-model="notes">
                    </textarea>
                </div>
              </div> -->

            </div>
            <!-- <div class="col-12 text-end">
              <button class="btn btn-submit me-2" type="submit">Submit</button>
              <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal fade" id="recents" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Hold Transactions</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="tabs-sets">
            <!-- <ul class="nav nav-tabs" id="myTabs" role="tablist"> -->
            <!-- <li class="nav-item" role="presentation">
                <button class="nav-link active" id="purchase-tab" data-bs-toggle="tab" data-bs-target="#purchase"
                  type="button" aria-controls="purchase" aria-selected="true" role="tab">
                  Hold
                </button>
              </li> -->
            <!-- <li class="nav-item" role="presentation">
                <button class="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button"
                  aria-controls="payment" aria-selected="false" role="tab">
                  Payment
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="return-tab" data-bs-toggle="tab" data-bs-target="#return" type="button"
                  aria-controls="return" aria-selected="false" role="tab">
                  Return
                </button>
              </li> -->
            <!-- </ul> -->
            <div class="tab-content">
              <div class="tab-pane fade show active" id="purchase" role="tabpanel" aria-labelledby="purchase-tab">
                <div class="table-top">
                  <div class="search-set">
                    <div class="search-input">
                      <a class="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg"
                          alt="img" /></a>
                    </div>
                  </div>
                  <!-- <div class="wordset">
                    <ul>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                            src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                            src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                            src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                      </li>
                    </ul>
                  </div> -->
                </div>
                <div class="table-responsive">
                  <table class="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in holdDataLocal" :key="index">
                        <td>{{ item.invoice_date }}</td>
                        <td>{{ item.customer }}</td>
                        <td>{{ rupiah(item.subtotal) }}</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);" @click="setItemHoldData(item)"
                            data-bs-dismiss="modal" aria-label="Close">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <!-- <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a> -->
                          <a class="me-3 confirm-text" href="javascript:void(0);" @click="deleteItemHoldData(index)">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="tab-pane fade" id="payment" role="tabpanel">
                <div class="table-top">
                  <div class="search-set">
                    <div class="search-input">
                      <a class="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg"
                          alt="img" /></a>
                    </div>
                  </div>
                  <div class="wordset">
                    <ul>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                            src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                            src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                            src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th class="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0101</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0102</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0103</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0104</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0105</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0106</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0107</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="return" role="tabpanel">
                <div class="table-top">
                  <div class="search-set">
                    <div class="search-input">
                      <a class="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg"
                          alt="img" /></a>
                    </div>
                  </div>
                  <div class="wordset">
                    <ul>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                            src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                            src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                            src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th class="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0101</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0102</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0103</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0104</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0105</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0106</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2022-03-07</td>
                        <td>0107</td>
                        <td>Walk-in Customer</td>
                        <td>$ 1500.00</td>
                        <td>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                          </a>
                          <a class="me-3" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                          </a>
                          <a class="me-3 confirm-text" href="javascript:void(0);">
                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="modal fade" id="delete" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Order Deletion</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="delete-order">
            <img src="../../../assets/img/icons/close-circle1.svg" alt="img" />
          </div>
          <div class="para-set text-center">
            <p>
              The current order will be deleted as no payment has been <br />
              made so far.
            </p>
          </div>
          <div class="col-lg-12 text-center">
            <a class="btn btn-danger me-2">Yes</a>
            <a class="btn btn-cancel" data-bs-dismiss="modal">No</a>
          </div>
        </div>
      </div>
    </div>
  </div>
   -->
</template>

<style scoped>
.text-primary {
  color: #1ba0e2 !important;
}
</style>
<script>



import Cookies from "js-cookie";
import axios from "axios";
import { ModelSelect } from 'vue-search-select';


import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, rupiahInput, rupiahoutput, AlertBottom } from "@/assets/js/function";

export default {
  emits: ['data_customer_insert', 'konfirmasi', 'marketing_id', 'notes'],
  data() {
    return {
      currentData: {
        fullname: "",
        email: "",
        telepone: "",
        whatsapp: "",
        job_title: "",
        dob: "",
        rate: "",
        discount_rate: 0,
        company: "",
        industry: "",
        interest: "",
        billing_address1: "",
        billing_address2: "",
        billing_address3: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_address3: "",
        shipping_name: "",
        default_terms: 0,



      },
      value: "",
      config: "",
      isLoad: true,
      pricingList: [
        {
          "id": "0",
          "text": "Regular"
        },
        {
          "id": "2",
          "text": "Silver"
        },
        {
          "id": "3",
          "text": "Gold"
        },
        {
          "id": "4",
          "text": "Bronze"
        },
        {
          "id": "5",
          "text": "Platinum"
        },
      ],
      marketing_id: "",
      notes: "",
      report_date: new Date(),
      currentDataReport: {},
      showbutton: true,
      holdDataLocal: [...this.holddata],
      return_amount: 0,
      payment_amount: 0,


    }

  },
  watch: {
    'report_date'(newValue, oldValue) {
      console.log('Selected Value:', newValue);

    },
    payment_amount: {
      handler(newValue) {


        let amount = 0;
        if (newValue == '0') {
          amount = 0;
        } else {
          amount = rupiahoutput(newValue) - this.total;
        }

        if (amount > 0) {
          this.return_amount = amount;
        } else {
          this.return_amount = 0;
        }

      },
    },

    'holdDataLocal': {
      handler(newValue) {
        if (this.isLoad == false) {
          localStorage.setItem('hold_data', JSON.stringify(newValue));
        }

      },
      deep: true
    },
    holddata: {
      handler(newData) {
        this.holdDataLocal = [...newData];  // Update local data on prop change
      },
      deep: true,
      immediate: true,
    },

  },
  props: {
    customer: String,
    total: BigInt,
    paymentmethod: String,
    marketingdata: Object,
    holddata: Object,

  },
  created() {
    this.currentData.dob = new Date();
    this.token = Cookies.get("token_refresh");
    this.config = {
      headers: { Authorization: `Bearer ` + this.token },
      timeout: 30000,
    }
  },
  mounted() {
    this.isLoad = false;
  },

  methods: {
    rupiah,
    fullPay() {
      this.payment_amount = rupiahInput(this.total);
    },
    formatCurrency(number) {
      this.payment_amount = rupiahInput(number);
    },
    copyFromBillingAddres() {

      this.currentData.shipping_address1 = this.currentData.billing_address1;
      this.currentData.shipping_address2 = this.currentData.billing_address2;
      this.currentData.shipping_address3 = this.currentData.billing_address3;
    },
    konfirmasi() {
      if (this.total > rupiahoutput(this.payment_amount)) {
        AlertPopup("error", "", 'Jumlah Pembayaran kurang', 1500, false);

        return true;
      }

      const closeButton = this.$refs.buttonclosemodalconfirmation;
      closeButton.click();
      const item_prepare = {
        notes: this.notes,
        return_amount: this.return_amount,
        payment_amount: rupiahoutput(this.payment_amount)

      }
      this.$emit('notes', item_prepare);
      this.$emit('konfirmasi', 'YA');
    },
    pilihmarketing() {
      const closeButton = this.$refs.buttonclosemodalpilihmarketing;
      closeButton.click();
      this.$emit('marketing_id', this.marketing_id)
      this.marketing_id = 0;
    },
    proses() {
      ShowLoading();
      let formData = new FormData();


      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("dob");

      if (this.currentData.dob != 'Invalid Date' && this.currentData.dob != new Date()) {
        var formattedDate = this.currentData.dob.toISOString().split('T')[0];
        formData.append("dob", formattedDate);
      } else {
        formData.append("dob", null);
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'customer_insert';
      } else {
        this.api = base_url + 'customer_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {

            // console.log(response);
            AlertBottom(response.data.message);



            this.$emit('data_customer_insert', response.data.data);
            const closeButton = this.$refs.buttonclosemodalcreate;
            closeButton.click();

            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    dailyreport() {
      ShowLoading();
      let formData = new FormData();


      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("report_date");

      if (this.report_date != 'Invalid Date' && this.report_date != new Date()) {
        var formattedDate = this.report_date.toISOString().split('T')[0];
        formData.append("report_date", formattedDate);
      } else {
        formData.append("report_date", null);
      }


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pos_report_daily';
      } else {
        this.api = base_url + 'pos_report_daily';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            this.currentDataReport = response.data.data;


            // console.log(response);
            // AlertBottom(response.data.message);


            // this.$emit('data_customer_insert', response.data.data);
            // const closeButton = this.$refs.buttonclosemodalcreate;
            // closeButton.click();

            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    printApi() {
      ShowLoading();
      let formData = new FormData();

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pos_print_report_daily';
      } else {
        this.api = base_url + 'pos_print_report_daily';
      }

      var formattedDate = this.report_date.toISOString().split('T')[0];
      formData.append("report_date", formattedDate);

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.status == 200) {
            this.ajaxPrint(response.data);
          } else {
            AlertPopup("error", "", "Sorry there is something error", 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    ajaxPrint(url) {

      CloseLoading();
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf('android') > -1;
      if (isAndroid) {
        // android_print(data);
        window.location.href = url;
      } else {
        this.print();
        // AlertPopup("error", "", 'The Device only support android', 1500, false);
      }

    },
    print() {
      this.showbutton = false;
      // Set the page size to A4
      const style = document.createElement('style');
      style.textContent = `@media print { @page { size: A4; } }`;
      document.head.appendChild(style);

      // Wait for any dynamic content to load before printing
      setTimeout(() => {
        window.print();

        // Show any hidden elements after printing
        document.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');

        // Remove the A4 page size style
        document.head.removeChild(style);
        // window.close();
        this.showbutton = true;
      }, 1000);

    },
    deleteItemHoldData(index) {
      this.holdDataLocal.splice(index, 1);


    },
    setItemHoldData(item) {
      this.$emit('data_hold_item', item);
    }

  },
  components: {
    ModelSelect

  },
}
</script>