<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.group_name" placeholder="" required />
                  </div>
                </div>
                <!-- for pilih tipe customer -->
                

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label> Tipe Customer :<span class="text-danger">*</span></label>

                    <!-- <multi-select :options="needData1" :selected-options="items" placeholder="select item"
                      @select="onSelect">
                    </multi-select> -->
                    <vue-select :options="tipeCustomerList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.tipe" placeholder="None">
                    </vue-select>
                  </div>
                </div>

                <!-- end -->


                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group" v-if="currentData.tipe === 'CUSTOMER'">
                    <label> Customer :<span class="text-danger">*</span></label>

                    <multi-select :options="needData1" :selected-options="items" placeholder="select item"
                      @select="onSelect">
                    </multi-select>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group" v-if="currentData.tipe === 'CUSTOMER_OPTI'">
                    <label> Customer Opti :<span class="text-danger">*</span></label>

                    <multi-select :options="needData2" :selected-options="items2" placeholder="select item"
                      @select="onSelect2">
                    </multi-select>
                  </div>
                </div>



                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="customergroup" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { MultiSelect } from 'vue-search-select';
import unionWith from 'lodash/unionWith'
import isEqual from 'lodash/isEqual'
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: { 
  //   Form,
  //   Field,
  // },
  name: "editcustomergroup",
  data() {
    return {
      filter: true,
      title: "Insert Customer Group",
      title1: "add new Customer Group",
      config: "",
      api: "",

      //data
      currentData: {
      },
      currentDate: new Date,

      needData1: [],
      needData2: [],

      searchText: '', // If value is falsy, reset searchText & searchItem
      items: [],
      items2: [],
      lastSelectItem: {},
      lastSelectItem2: {},


      // for new select tipe customer
      selectedTipe: '',  
      tipeCustomerList: [
        { id: 'CUSTOMER', text: 'Customer' },
        { id: 'CUSTOMER_OPTI', text: 'Customer Opti' }
      ],
      
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    

  },
  // watch: {

    
  //   'currentData.tipe': function(newVal) {
  //     console.log(newVal)
    
  //   }
  // },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  mounted() {
    //First upload
    const listData = JSON.parse(localStorage.getItem('needData1'));
    const listData2 = JSON.parse(localStorage.getItem('needData2'));

    listData.forEach((item, index) => {
      const item_prepare = { value: item.id, text: item.fullname };
      this.needData1.push(item_prepare);
    });

    listData2.forEach((item, index) => {
      const item_prepare = { value: item.id, text: item.fullname };
      this.needData2.push(item_prepare);
    });

    // console.log(this.needData1);
    // this.needData1 



  },
  methods: {
    

    onSelect(items, lastSelectItem) {
      this.items = items
      this.lastSelectItem = lastSelectItem
    },

    onSelect2(items, lastSelectItem) {
      this.items2 = items
      this.lastSelectItem2 = lastSelectItem
    },
    // deselect option
    reset() {
      this.items = [] // reset
    },
    // select option from parent component
    selectOption() {
      this.items = unionWith(this.items, [this.options[0]], isEqual)
    },
    insert() {

      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete('id_customer');
      // formData.delete('id_customer_opti');

       // Merge id_customer and id_customer_opti into one array
      let id_customer = [];

      // If tipe is CUSTOMER, add items from this.items
      if (this.currentData.tipe === 'CUSTOMER') {
        this.items.forEach((item) => {
          id_customer.push(item.value);
        });
      }

      // If tipe is CUSTOMER_OPTI, add items from this.items2
      if (this.currentData.tipe === 'CUSTOMER_OPTI') {
        this.items2.forEach((item) => {
          id_customer.push(item.value);
        });
      }

      // Append the combined id_customer to formData
      formData.append('id_customer', id_customer.join(','));

      // console.log('id_customer:', id_customer);
      
      
      

      
      // console.log('id_customer_opti:', id_customer_opti);


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'crm_customer_group_insert';
      } else {
        this.api = base_url + 'crm_customer_group_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("customergroup");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },
  components: {

    MultiSelect

  },

};
</script>
