<template>
    <div class="container mt-3" style="font-size: 16px!important;">

        <div class="row d-flex justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12  flex-item">
                <div class="w-100 mt-3 p-2 bg-white border-radius-8 ">
                    <!-- <div class="bg-header"></div> -->
                    <img :src="currentData.logo" width="150" height="150">
                    <!-- <img src="https://levelup.arthaskyline.com/assets/img/custom/logo_small_color.png" width="150"
                        height="150" v-else> -->

                    <!-- <h2 class="m-3 mt-4">INVOICE</h2> -->

                    <div class="d-flex justify-content-center align-items-end text-primary fw-bold fs-5">
                        INVOICE

                    </div>
                    <div class="d-flex justify-content-center align-items-end  fw-bold fs-5 mt-3">
                        {{ currentData.nama_pt }}
                    </div>
                    <hr>
                    <!-- table pc/tablet -->
                    <table
                        class="w-100 table border-bottom border-secondary border-1 d-md-block d-sm-block d-lg-block d-xl-block d-none ">
                        <tr>
                            <td style="width: 75%;" class="text-start"><span class="text-uppercase fw-bold"
                                    v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.company }}
                                </span></td>

                            <td class="text-start" style="width: 12.5%;">
                                invoice No
                            </td>

                            <td class="text-end" style="width: 1%;">

                                {{ currentData.invoice_no }}
                            </td>

                        </tr>

                        <tr>
                            <td class="text-start">
                                <span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.fullname }}

                                </span>
                                <br>
                                <span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.whatsapp }}
                                </span>


                            </td>

                            <td class="text-start align-middle">
                                Invoice Date
                            </td>

                            <td class="text-end align-middle">

                                {{ currentData.invoice_date }}
                                <!-- {{ formatDateToString(currentData.created_at) }} -->
                            </td>

                        </tr>
                        <tr>
                            <td class="text-start">
                                <span class=""
                                    v-if="currentData.id_customer > 0 && currentData.customer_detail.billing_address1 != ''">
                                    {{ currentData.customer_detail.billing_address1 }}
                                </span>
                                <span class=""
                                    v-if="currentData.id_customer > 0 && currentData.customer_detail.billing_address1 == '' && currentData.customer_detail.billing_address2 != ''">
                                    {{ currentData.customer_detail.billing_address2 }}
                                </span>
                                <span class=""
                                    v-if="currentData.id_customer > 0 && currentData.customer_detail.billing_address1 == '' && currentData.customer_detail.billing_address2 == '' && currentData.customer_detail.billing_address3 != ''">
                                    {{ currentData.customer_detail.billing_address3 }}
                                </span>
                            </td>
                            <td class="text-start">
                                Due Date
                            </td>
                            <td class="text-end">

                                {{ currentData.due_date }}
                            </td>


                        </tr>
                        <tr>
                            <td class="text-start"><span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    <!-- {{ currentData.customer_detail.email }} -->
                                </span></td>
                            <td class="text-start">
                                <label v-if="currentData.po_number">PO No.</label>
                            </td>
                            <td class="text-end">
                                <label v-if="currentData.po_number">{{ currentData.po_number }}</label>
                            </td>


                        </tr>
                    </table>

                    <table
                        class="w-100 table border-bottom border-secondary border-1 d-block d-md-none d-sm-none d-lg-none d-xl-none ">
                        <tr class=" ">
                            <td class="text-start" style="width: 50%;">
                                Invoice No
                            </td>
                            <td class="text-end" style="width: 10%;">
                                {{ currentData.invoice_no }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-start">
                                Invoice Date
                            </td>
                            <td class="text-end">

                                {{ currentData.invoice_date }}
                            </td>

                        </tr>
                        <tr>
                            <td class="text-start">
                                Due Date
                            </td>
                            <td class="text-end">
                                {{ currentData.due_date }}
                            </td>

                        </tr>
                        <tr v-if="currentData.po_number != ''">
                            <td class="text-start">
                                PO No.
                            </td>
                            <td class="text-end">
                                {{ currentData.po_number }}
                            </td>

                        </tr>

                        <tr>
                            <td class="text-start"><span class="text-uppercase fw-bold"
                                    v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.company }}
                                </span></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td class="text-start"><span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.fullname }}
                                    <br>
                                    {{ currentData.customer_detail.whatsapp }}
                                </span></td>

                            <td class="text-end"></td>
                        </tr>
                        <tr>
                            <td class="text-start">

                                <span class=""
                                    v-if="currentData.id_customer > 0 && currentData.customer_detail.billing_address1 != ''">
                                    {{ currentData.customer_detail.billing_address1 }}
                                </span>
                                <span class=""
                                    v-if="currentData.id_customer > 0 && currentData.customer_detail.billing_address1 == '' && currentData.customer_detail.billing_address2 != ''">
                                    {{ currentData.customer_detail.billing_address2 }}
                                </span>
                                <span class=""
                                    v-if="currentData.id_customer > 0 && currentData.customer_detail.billing_address1 == '' && currentData.customer_detail.billing_address2 == '' && currentData.customer_detail.billing_address3 != ''">
                                    {{ currentData.customer_detail.billing_address3 }}
                                </span>

                            </td>
                            <td class="text-end"> </td>
                        </tr>
                        <tr>
                            <td class="text-start">
                                <!-- <span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.email }}
                                </span> -->
                            </td>
                            <td class="text-end">
                            </td>
                        </tr>
                    </table>

                    <div class="table-responsive">
                        <table class="w-100 table border-bottom border-secondary border-1 mt-3">
                            <tr class="border-bottom border-secondary border-1 fw-bold">

                                <th class="text-start pe-4">ITEM</th>
                                <th class="text-end pe-4">QTY</th>
                                <th class="text-end pe-4">PRICE</th>

                                <th class="text-end pe-4">TOTAL</th>
                            </tr>
                            <tr v-for="item in currentData.invoice_detail" :key="item.id"
                                class="border-bottom border-secondary border-1">
                                <td class="text-start pe-4" style="min-width: 200px!important;">
                                    <span class="fw-bold">{{ item.name }}</span><br>
                                    <div class="ms-5">
                                        <span class="fw-normal " v-html="convertNewlinesToBr(item.deskripsi)"></span>
                                    </div>

                                    <br>
                                    <span style="font-style: italic!important;" v-if="item.nama_marketing != ''">*{{
                                        item.nama_marketing }}</span>

                                </td>


                                <td class="text-end pe-4">

                                    {{ item.qty }}

                                </td>
                                <td class="text-end pe-4">
                                    {{ rupiah(item.rate) }}

                                </td>
                                <td class="text-end pe-4">
                                    {{ rupiah(item.item_total) }}

                                </td>
                            </tr>
                            <!-- <?php foreach ($data_invoices as $dd) { ?>
                        <tr>
                            <td class="text-start">
                                <?= $dd['qty'] ?>X<br>
                                <?= $dd['name'] ?><br>
                                <?= $dd['description'] ?>
                            </td>
                            <td class="text-end">
                                <?= number_format($dd['rate'],0) ?>
                            </td>
                            <td class="text-end">
                                <?= number_format($dd['item_total'],0) ?>
                            </td>
                        </tr>
                        <?php } ?> -->
                        </table>
                    </div>

                    <table class="w-100 table  border-bottom border-secondary border-1 mt-3">
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Net</td>
                            <td class="text-end">
                                {{ rupiah(currentData.subtotal) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Subtotal</td>
                            <td class="text-end">
                                {{ rupiah(currentData.subtotal) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Discount</td>
                            <td class="text-end">
                                {{ rupiah(currentData.discount_amount) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">{{ currentData.tax_name }}</td>
                            <td class="text-end">
                                {{ rupiah(currentData.tax_rate) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Total</td>
                            <td class="text-end fw-bold">
                                {{ rupiah(currentData.grand_total) }}
                                <!-- <?= number_format($data['grand_total'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Payment using

                                <!-- <?= str_replace("_", " ", $data['payment_method']) ?> -->
                            </td>
                            <td class="text-end">
                                {{ currentData.payment_method }}
                                <!-- <?= number_format($data['payment_amount'],0) ?> -->
                            </td>
                        </tr>

                        <tr class="border-bottom border-secondary border-1"
                            v-if="currentData.customer_detail != undefined && currentData.customer_detail.amount_deposit > 0">
                            <td class="text-start fw-bold">Balance
                            </td>
                            <td class="text-end">
                                {{ rupiah(currentData.customer_detail.amount_deposit) }}
                            </td>
                        </tr>
                        <!-- <tr class="border-bottom border-secondary border-1"
                            >
                            <td class="text-start fw-bold">Balance
                            </td>
                            
                        </tr> -->



                    </table>

                    <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-start">
                        Note : <br>
                        <i>{{ currentData.notes }}</i>
                    </div>
                    <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-start">
                        <i>* Deposit payments cannot be refunded</i>
                    </div>
                    <div class="my-4 text-start">
                        <span class="fw-bold">Payment Info</span>
                        <br>
                        {{ currentData.bank_name + ' , ' + currentData.bank_account_number + ' , ' +
                            currentData.bank_account_name }}
                    </div>
                </div>


                <!-- <?php if ($referal == "") { ?> -->
                <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center"
                    v-if="isCustomer == true && currentData.total_outstanding == '0'">
                    <a class="btn btn-warning form-control mb-1" href="javascript:void(0);" @click="berikanUlasan()"
                        v-show="showbutton == true">Berikan
                        Ulasan</a>
                </div>
                <!-- <?php } else { ?> -->
                <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center d-flex justify-content-center"
                    v-if="currentData.total_outstanding != '0' && currentData.fitur_online_payment == 'AKTIF' && isCustomer == true && currentData.customer_detail != ''">
                    <div class="w-100">
                        <a class="btn btn-primary form-control mb-1" href="javascript:void(0);"
                            @click="paymentMethodApi(); showModal('paymentMethod');" v-show="showbutton == true">Bayar
                            Online</a>
                        <a class="btn btn-outline-primary form-control mb-1" href="javascript:void(0);"
                            @click="refreshPage" v-show="showbutton == true">Cek Pembayaran</a>
                    </div>


                </div>


                <!-- <?php } else { ?> -->
                <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center d-flex justify-content-center"
                    v-if="isCustomer == false">
                    <!-- <a class="btn btn-success form-control mb-1" id="captureButton" href="#">Simpan Invoice</a> -->
                    <a class="btn btn-outline-primary form-control mb-1" href="javascript:void(0);" @click="printApi()"
                        v-show="showbutton == true">Print</a>

                </div>
                <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center d-flex justify-content-center"
                    v-if="isCustomer == false">
                    <!-- <a class="btn btn-success form-control mb-1" id="captureButton" href="#">Simpan Invoice</a> -->
                    <a class="btn btn-outline-warning form-control mb-1" href="javascript:void(0);"
                        @click="goPage('pos')" v-show="showbutton == true">Back</a>

                </div>

                <p style="font-size:9px;" class="text-muted mt-3 mb-5 text-center">
                    &copy;{{ currentYear }}ArthaSkyline.com<br>PT. SHANKARA CABOT BAJRA DANAPATI
                </p>

            </div>
        </div>
    </div>
</template>

<style scoped>
.table>:not(caption)>*>* {
    padding: 0px !important;
}

table {
    border-collapse: separate;
    border-spacing: 0 1em;
}

.bg-all {
    background-color: #f6f6f6 !important;
}

.table {
    border-bottom-width: 1px !important;
}

.bg-header {
    background-color: #dc3545 !important;
    color: #ffffff !important;
    height: 10px;
    margin: -9px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.btn-danger {
    color: #ffffff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-outline-danger {
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.text-muted {
    color: #cacaca !important;
}

.text-primary {
    color: #1ba0e2 !important;
}
</style>

<script>
import { ref } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Cookies from "js-cookie";
import axios from "axios";


import { ShowLoading, CloseLoading, formatDateToString, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, DateFormat, refreshPage } from "@/assets/js/function";


export default {

    data() {
        return {
            currentData: [],
            currentYear: "",
            api: "",
            config: '',
            isCustomer: false,
            token: "",
            showbutton: true,
            merchantOrderId: "",
            paymentList: "",
            // isPay: '',

        }

    },
    mounted() {
        var url = window.location.href;

        // Create a new URL object
        var urlObject = new URL(url);

        // Get the value of the 'token' parameter
        var token = urlObject.searchParams.get('token');
        this.token = token;
        if (token == null) {
            this.loadDataFromLocalToken();
        } else {
            this.isCustomer = true;
            this.loadDataFromUrlToken(token);
        }

        // // Get the current year
        // this.currentYear = new Date().getFullYear();

    },
    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }

    },

    methods: {
        formatDateToString,
        rupiah,
        DateFormat,
        goPage,
        refreshPage,
        convertNewlinesToBr(text) {
            // Replace newlines with <br> tags
            return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        print() {
            this.showbutton = false;
            // Set the page size to A4
            const style = document.createElement('style');
            style.textContent = `@media print { @page { size: A4; } }`;
            document.head.appendChild(style);

            // Wait for any dynamic content to load before printing
            setTimeout(() => {
                window.print();

                // Show any hidden elements after printing
                document.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');

                // Remove the A4 page size style
                document.head.removeChild(style);
                // window.close();
                this.showbutton = true;
            }, 1000);

        },
        ajaxPrint(url) {

            CloseLoading();
            const ua = navigator.userAgent.toLowerCase();
            const isAndroid = ua.indexOf('android') > -1;
            if (isAndroid) {
                // android_print(data);

                window.location.href = url;
            } else {
                this.print();
                // AlertPopup("error", "", 'The Device only support android', 1500, false);
            }
            // fetch(url)
            //     .then(response => response.text())
            //     .then(data => {
            //         const ua = navigator.userAgent.toLowerCase();
            //         const isAndroid = ua.indexOf('android') > -1;
            //         if (isAndroid) {
            //             // android_print(data);

            //             window.location.href = data;
            //         } else {
            //             // pc_print(data); // Uncomment if you have a pc_print function
            //         }
            //     })
            //     .catch(() => {
            //         alert('Please make sure printer is connected');
            //     })
            //     .finally(() => {
            //         btn.innerText = oldText;
            //     });
        },

        printApi() {
            ShowLoading();
            let formData = new FormData();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'print_invoice';
            } else {
                this.api = base_url + 'print_invoice';
            }

            formData.append("slug", localStorage.getItem('slug'));
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.status == 200) {
                        this.ajaxPrint(response.data);
                    } else {
                        AlertPopup("error", "", "Sorry there is something error", 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },

        paymentMethodApi() {
            ShowLoading();
            if (process.env.NODE_ENV === "development") {
                this.api = 'https://micro-payment.arthaskyline.com/api';
            } else {
                this.api = 'https://micro-payment.arthaskyline.com/api';
            }

            const dataPayment = {
                "apikey": "a4aef174-6a69-11ef-9f72-06573f41f314",
                "action": "getPaymentMethod",
                "paymentAmount": this.currentData.grand_total
            }

            axios
                .post(this.api, dataPayment, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    if (response.data.status == true) {
                        this.paymentList = response.data.data.paymentList;

                        CloseLoading();
                    } else {
                        AlertPopup("error", "", "Sorry there is something error", 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });



        },

        payApi(paymentMethod) {
            ShowLoading();
            // let formData = new FormData();

            if (process.env.NODE_ENV === "development") {
                this.api = 'https://micro-payment.arthaskyline.com/api';
            } else {
                this.api = 'https://micro-payment.arthaskyline.com/api';
            }

            const baseUrl = `${window.location.protocol}//${window.location.host}/`;


            if (this.currentData.customer_detail.email == "") {
                this.currentData.customer_detail.email = 'support@levelup.arthaskyline.com';
            }
            const dataCustomer = {
                apikey: 'a4aef174-6a69-11ef-9f72-06573f41f314',
                action: "createInvoice",
                firstName: this.currentData.customer_detail.fullname,
                lastName: "",
                email: this.currentData.customer_detail.email,
                paymentMethod: paymentMethod,
                paymentAmount: this.currentData.grand_total,
                // paymentAmount: 100000,
                returnUrl: baseUrl + "view?token=" + this.token,
                expiryPeriod: 60,
                // productName: this.currentData.invoice_detail.name,
                productName: this.currentData.invoice_no + '#' + this.currentData.id_profiles,
                // productDetails: [
                //     {
                //         name: this.currentData.invoice_no,
                //         price: this.currentData.grand_total,
                //         qty: 1

                //     }
                // ]
            }
            console.log(dataCustomer);

            // const productDetails = [
            //         {
            //             name: "Level Up - " + this.currentData.invoice_no,
            //             price: this.currentData.grand_total,
            //             qty: this.currentData.invoice_detail[0].qty

            //         }
            // ]

            // const productDetailsJson = JSON.stringify(productDetails);

            // Object.entries(dataCustomer).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });

            // console.log(dataCustomer);

            // for (let [key, value] of formData.entries()) {
            //   console.log(`${key}: ${value}`);
            // }


            axios
                .post(this.api, dataCustomer, {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {
                        // this.currentData = response.data.data.invoices[0];
                        // const paymentUrl = response.data.data.invoices.paymentUrl;
                        // Redirect to the payment URL
                        // window.location.href = paymentUrl;

                        // goPage('invoice')
                        CloseLoading();
                        this.merchantOrderId = response.data.data.invoices.merchantOrderId;
                        localStorage.setItem('merchantOrderId', response.data.data.invoices.merchantOrderId);

                        window.location.href = response.data.data.invoices.paymentUrl;


                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                    // if (response.data.status == false) {
                    //     // window.location.href = "https://arthaskyline.com/level-up.html";

                    // }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },



        // checkStatusApi() {
        //     ShowLoading();
        //     // let formData = new FormData();

        //     if (process.env.NODE_ENV === "development") {
        //         this.api = 'https://micro-payment.arthaskyline.com/api';
        //     } else {
        //         this.api = 'https://micro-payment.arthaskyline.com/api';
        //     }

        //     const baseUrl = `${window.location.protocol}//${window.location.host}/`;


        //     const dataMerchant = {
        //         apikey: 'a4aef174-6a69-11ef-9f72-06573f41f314',
        //         action: "transactionStatus",
        //         merchantOrderId: this.merchantOrderId,
        //     }

        //     axios
        //         .post(this.api, dataMerchant, {
        //             headers: {
        //                 // Overwrite Axios's automatically set Content-Type
        //                 'Content-Type': 'application/json'
        //             }
        //         })
        //         .then((response) => {
        //             if (response.data.status == true) {
        //                 // this.currentData = response.data.data.invoices[0];
        //                 // const paymentUrl = response.data.data.invoices.paymentUrl;
        //                 // Redirect to the payment URL
        //                 // window.location.href = paymentUrl;

        //                 // goPage('invoice')
        //                 CloseLoading();
        //                 this.merchantOrderId = response.data.data.invoices.merchantOrderId;
        //                 localStorage.setItem('merchantOrderId', response.data.data.invoices.merchantOrderId);

        //                 window.location.href = response.data.data.invoices.paymentUrl;


        //             } else {
        //                 AlertPopup("error", "", "Sorry there is something error", 1500, false);

        //             }
        //             // if (response.data.status == false) {
        //             //     // window.location.href = "https://arthaskyline.com/level-up.html";

        //             // }
        //         })
        //         .catch((error) => {

        //             ErrorConnectionTimeOut(error);
        //         });


        // },

        berikanUlasan() {
            localStorage.setItem('token_feedback', this.token);
            goPage('feedback');
        },
        loadDataFromLocalToken() {
            ShowLoading();
            let formData = new FormData();
            formData.append("slug", localStorage.getItem('slug'));


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'invoices_slug';
            } else {
                this.api = base_url + 'invoices_slug';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.currentData = response.data.data.invoices[0];




                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                        window.location.href = "https://arthaskyline.com/level-up.html";

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });
        },
        loadDataFromUrlToken(token) {
            ShowLoading();
            let formData = new FormData();
            formData.append("slug", token);


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'invoices_slug';
            } else {
                this.api = base_url + 'invoices_slug';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.currentData = response.data.data.invoices[0];

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                        window.location.href = "https://arthaskyline.com/level-up.html";
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });
        }

    },

    name: "viewinvoice",
};
</script>